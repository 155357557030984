import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ClientButton"] */ "/app/src/components/Button/Button.client.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Header/edu-logo.png");
;
import(/* webpackMode: "eager" */ "/app/src/components/Header/header.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/Header/logo-gradle.svg");
